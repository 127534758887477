exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-ifc-data-viewer-index-js": () => import("./../../../src/pages/ifc-data-viewer/index.js" /* webpackChunkName: "component---src-pages-ifc-data-viewer-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-orig-gatsby-js": () => import("./../../../src/pages/index.orig.gatsby.js" /* webpackChunkName: "component---src-pages-index-orig-gatsby-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-quantity-take-off-archicad-addon-index-js": () => import("./../../../src/pages/quantity-take-off-archicad-addon/index.js" /* webpackChunkName: "component---src-pages-quantity-take-off-archicad-addon-index-js" */),
  "component---src-pages-selection-archicad-addon-index-js": () => import("./../../../src/pages/selection-archicad-addon/index.js" /* webpackChunkName: "component---src-pages-selection-archicad-addon-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

